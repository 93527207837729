import React, { useState } from "react"
import Link from "gatsby-link"
import { useStaticQuery, graphql } from "gatsby"
import myInitObject from "../ultis/variable"
import NavStyled from "./styles/NavStyles"
import uuid from "react-uuid"

const Header = ({ linkLang }) => {
  const data = useStaticQuery(graphql`
    query {
      swapi {
        headerSingleton(lang: "pl") {
          logo_header {
            path
          }
          menu {
            value
          }
          eu_flag_new {
            path
          }
          eu_flagLink
        }
      }
    }
  `)
  const { headerSingleton } = data.swapi
  const [isOpen, toggleOpen] = useState(false)
  const [navOpen, toggleNav] = useState(false)

  let linkLangPL
  let linkLangEN

  if (linkLang.langKey === "en") {
    linkLangEN = linkLang.slug
    linkLangPL = linkLang.slug.slice(3)
  } else {
    linkLangPL = linkLang.slug
    linkLangEN = "/en" + linkLang.slug
  }

  return (
    <header>
      <div className="container">
        <div className="logo">
          <Link to="/">
            <img
              src={myInitObject.cockpitUrl + headerSingleton.logo_header.path}
            />
          </Link>
        </div>
        <div className="header__right">
          <NavStyled>
            <ul>
              {headerSingleton.menu.map(item => {
                return (
                  <li
                    key={uuid()}
                    className={item.value.submenu ? "has__child" : ""}
                  >
                    {item.value.submenu ? (
                      <>
                        <span activeClassName={"active"} partiallyActive={true}>
                          {item.value.text}
                        </span>
                        <ul>
                          {item.value.submenu.map(item => {
                            return (
                              <li key={uuid()}>
                                <Link
                                  to={item.value.sublink}
                                  activeClassName={"active"}
                                  partiallyActive={true}
                                >
                                  {item.value.subtext}
                                </Link>
                              </li>
                            )
                          })}
                        </ul>
                      </>
                    ) : (
                      <Link
                        to={item.value.link}
                        activeClassName={"active"}
                        partiallyActive={true}
                      >
                        {item.value.text}
                      </Link>
                    )}
                  </li>
                )
              })}
            </ul>
          </NavStyled>
          <div className="header__lang">
            <NavStyled>
              <Link to={linkLangPL}>
                <strong>pl</strong>
              </Link>
              <span>|</span>
              <Link to={linkLangEN}>en</Link>
            </NavStyled>
          </div>
          <div className="header__flag">
            {headerSingleton.eu_flagLink === "#" ? (
              <img
                src={myInitObject.cockpitUrl + headerSingleton.eu_flag_new.path}
              />
            ) : (
              <Link to={headerSingleton.eu_flagLink}>
                <img
                  src={
                    myInitObject.cockpitUrl + headerSingleton.eu_flag_new.path
                  }
                />
              </Link>
            )}
          </div>
        </div>
        <div
          className={`hamburger ${isOpen ? "hamburger--active" : ""}`}
          onClick={() => {
            toggleOpen(!isOpen)
          }}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div className={`mobileNav ${isOpen ? "mobileNav--active" : ""}`}>
          <NavStyled>
            <ul>
              {headerSingleton.menu.map(item => {
                return (
                  <li
                    key={uuid()}
                    className={`${item.value.submenu ? "has__child" : ""} ${
                      navOpen ? "navOpen--open" : ""
                    }`}
                  >
                    {item.value.submenu ? (
                      <>
                        {item.value.link == "#" || item.value.link == "/#" ? (
                          <span onClick={() => toggleNav(!navOpen)}>
                            {item.value.text}
                          </span>
                        ) : (
                          <Link
                            to={item.value.link}
                            activeClassName={"active"}
                            partiallyActive={true}
                          >
                            {item.value.text}
                          </Link>
                        )}
                        <ul>
                          {item.value.submenu.map(item => {
                            return (
                              <li key={uuid()}>
                                <Link
                                  to={item.value.sublink}
                                  activeClassName={"active"}
                                  partiallyActive={true}
                                >
                                  {item.value.subtext}
                                </Link>
                              </li>
                            )
                          })}
                        </ul>
                      </>
                    ) : (
                      <Link
                        to={item.value.link}
                        activeClassName={"active"}
                        partiallyActive={true}
                      >
                        {item.value.text}
                      </Link>
                    )}
                  </li>
                )
              })}
            </ul>
          </NavStyled>
          <div className="header__lang">
            <NavStyled>
              <NavStyled>
                <Link to={linkLangPL}>
                  <strong>pl</strong>
                </Link>
                <span>|</span>
                <Link to={linkLangEN}>en</Link>
              </NavStyled>
            </NavStyled>
          </div>
          <div className="header__flag">
            {headerSingleton.eu_flagLink === "#" ? (
              <img
                src={myInitObject.cockpitUrl + headerSingleton.eu_flag_new.path}
              />
            ) : (
              <Link to={headerSingleton.eu_flagLink}>
                <img
                  src={
                    myInitObject.cockpitUrl + headerSingleton.eu_flag_new.path
                  }
                />
              </Link>
            )}
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
