import React from "react"
import Header from "./header"
import HeaderEN from "./headerEn"

import LoyautStyled from "./styles/LoyoutStyles"
import GlobalStyle from "./styles/GlobalStyles"
import Footer from "./footer.js"
import FooterEN from "./footerEN.js"

const Layout = props => {
  const { children, pageContext, nameClass } = props

  return (
    <LoyautStyled>
      <GlobalStyle />
      {pageContext ? (
        pageContext.langKey === "pl" ? (
          <Header linkLang={pageContext} />
        ) : (
          <HeaderEN linkLang={pageContext} />
        )
      ) : (
        <Header />
      )}

      <main className={nameClass}>{children}</main>
      {pageContext ? (
        pageContext.langKey === "pl" ? (
          <Footer />
        ) : (
          <FooterEN />
        )
      ) : (
        <Footer />
      )}
    </LoyautStyled>
  )
}

export default Layout
