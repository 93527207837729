import styled from "styled-components"

const LayoutStyled = styled.div`
  header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 20px 40px;
    z-index: 40;
    background: #fff;
    @media (max-width: 1280px) {
      padding: 32px 40px;
    }
    .container {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
    .logo {
      position: absolute;
      left: 40px;
      top: 50%;
      transform: translateY(-50%);
      @media (max-width: 600px) {
        left: 20px;
      }
      img {
        width: 215px;
      }
    }
  }
  .header {
    &__lang {
      margin: -3px 34px 0 30px;
      @media (max-width: 1280px) {
        margin: 0;
        color: #fff;
        nav {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        a {
          margin: 0 10px;
        }
      }
      span {
        font-size: 1.2em;
      }
    }
    &__right {
      display: flex;
      align-items: center;
    }
    &__flag {
      display: flex;
      @media (max-width: 1280px) {
        background: #fff;
        justify-content: center;
        padding: 20px 0;
        position: absolute;
        bottom: 60px;
        left: 0;
        right: 0;
      }
    }
  }
  .hamburger {
    height: 20px;
    position: absolute;
    right: 40px;
    @media (max-width: 600px) {
      right: 20px;
    }
    span {
      height: 2px;
      width: 30px;
      background: #000000;
      position: absolute;
      transition: 0.3s all linear;
      border-radius: 2px;
    }
    span:nth-of-type(1) {
      top: 0;
      right: 0;
    }
    span:nth-of-type(2) {
      width: 25px;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }
    span:nth-of-type(3) {
      top: 100%;
      right: 0;
      transform: translateY(-100%);
    }
    &--active {
      span:nth-of-type(1) {
        top: 50%;
        transform: translateY(-50%) rotate(-45deg);
        color: #fff;
      }
      span:nth-of-type(2) {
        opacity: 0;
        transform: translate(-100%, -50%);
        color: #fff;
      }
      span:nth-of-type(3) {
        top: 50%;
        transform: translateY(-50%) rotate(45deg);
        color: #fff;
      }
    }
  }
  .mobileNav {
    position: absolute;
    /* top: 0; */
    top: 100%;
    height: 100vh;
    z-index: 200;
    width: 100%;
    left: 0;
    background: #000;
    transition: 0.3s all;
    transform: translateX(-100vw);
    &--active {
      transform: translateX(0);
    }
  }
`

export default LayoutStyled
