import styled from "styled-components"
import img from "../../../static/arrow_down.svg"

const NavStyled = styled.nav`
  ul {
    display: flex;
    align-items: center;
    margin: 0;
    @media (max-width: 1280px) {
      display: flex;
      color: #fff;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      /* height: calc(100vh - 170px); */
      padding-top: 40px;
      max-height: calc(100vh - 210px);
      overflow: auto;
    }
  }
  li {
    position: relative;
    &:not(:last-child) {
      margin-right: 30px;
      @media (max-width: 1280px) {
        margin-right: 0;
      }
    }
    @media (max-width: 1280px) {
      margin-right: 0;
      margin: 10px 0;
    }
    span,
    a {
      cursor: pointer;
      transition: 0.3s all;
      font-weight: 400;
      &:hover {
        opacity: 0.5;
      }
    }
    ul {
      position: absolute;
      flex-direction: column;
      align-items: flex-start;
      top: calc(100% - 20px);
      width: 250px;
      padding: 10px;
      line-height: 2.1em;
      background: #fff;
      transition: 0.3s all;
      visibility: hidden;
      opacity: 0;
      @media (max-width: 1280px) {
        height: auto;
        left: 0;
        visibility: visible;
        opacity: 1;
        background: #000;
        position: relative;
        top: initial;
        overflow: hidden;
        max-height: 0;
        transition: 0.3s all;
      }
      li {
        @media (max-width: 1280px) {
          margin: 10px 0;
        }
      }
    }
    &.navOpen--open {
      display: block;
      ul {
        margin-top: 5px;
        margin-left: -25px;
        max-height: 200px;
      }
    }
  }
  .has__child {
    position: relative;
    display: flex;
    align-items: center;
    @media (max-width: 1280px) {
      flex-direction: column;
    }

    ul {
      @media (max-width: 1280px) {
        padding: 0;
        li {
          width: 100%;
          text-align: center;
        }
      }
    }
    &:hover {
      ul {
        visibility: visible;
        top: calc(100% + 0px);
        opacity: 1;
      }
    }
    span:after {
      top: -2px;
      margin-left: 7px;
      position: relative;
      content: "";
      background: url(${img});
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      width: 12px;
      height: 6px;
      display: inline-block;
      @media (max-width: 1280px) {
        width: 20px;
        height: 10px;
        display: inline-block;
        filter: contrast(0) brightness(10.5);
        right: -20px;
        position: absolute;
        top: 10px;
      }
    }
  }
  span,
  a {
    padding: 2px 4px;
    font-weight: 400;
    font-size: 18px;
    cursor: pointer;
    @media (max-width: 1280px) {
      font-size: 21px;
      padding: 10px 0;
      text-align: center;
    }
    /* &.active {
      background: var(--white);
      color: var(--purple);
    } */
  }
`

export default NavStyled
