import { createGlobalStyle } from "styled-components"

const GlobalStyle = createGlobalStyle`
  

  :root {
    --white: #fff;
    --black: #000;
    --purple: rebeccapurple;
    --grey: #bbb;
  }

@font-face {
  font-family: "Helvetica Neue";
  src: url("../../fonts/HelveticaNeue.eot"); /* IE9 Compat Modes */
  src: url("../../fonts/HelveticaNeue.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("../../fonts/HelveticaNeue.otf") format("opentype"), /* Open Type Font */
    url("../../fonts/HelveticaNeue.svg") format("svg"), /* Legacy iOS */
    url("../../fonts/HelveticaNeue.ttf") format("truetype"), /* Safari, Android, iOS */
    url("../../fonts/HelveticaNeue.woff") format("woff"), /* Modern Browsers */
    url("../../fonts/HelveticaNeue.woff2") format("woff2"); /* Modern Browsers */
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("../../fonts/HelveticaNeue-Light.eot"); /* IE9 Compat Modes */
  src: url("../../fonts/HelveticaNeue-Light.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("../../fonts/HelveticaNeue-Light.otf") format("opentype"), /* Open Type Font */
    url("../../fonts/HelveticaNeue-Light.svg") format("svg"), /* Legacy iOS */
    url("../../fonts/HelveticaNeue-Light.ttf") format("truetype"), /* Safari, Android, iOS */
    url("../../fonts/HelveticaNeue-Light.woff") format("woff"), /* Modern Browsers */
    url("../../fonts/HelveticaNeue-Light.woff2") format("woff2"); /* Modern Browsers */
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("../../fonts/HelveticaNeue-Bold.woff") format("woff"), /* Modern Browsers */
    url("../../fonts/HelveticaNeue-Bold.woff2") format("woff2"); /* Modern Browsers */
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("../../fonts/HelveticaNeue-Medium.eot"); /* IE9 Compat Modes */
  src: url("../../fonts/HelveticaNeue-Medium.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("../../fonts/HelveticaNeue-Medium.otf") format("opentype"), /* Open Type Font */
    url("../../fonts/HelveticaNeue-Medium.svg") format("svg"), /* Legacy iOS */
    url("../../fonts/HelveticaNeue-Medium.ttf") format("truetype"), /* Safari, Android, iOS */
    url("../../fonts/HelveticaNeue-Medium.woff") format("woff"), /* Modern Browsers */
    url("../../fonts/HelveticaNeue-Medium.woff2") format("woff2"); /* Modern Browsers */
  font-weight: 500;
  font-style: normal;
}

  html {
    font-family: "Helvetica Neue" ,sans-serif;
    font-weight: 300;
    color: var(--black);
    background: var(--white);
    box-sizing: border-box;
  }

  body {
    line-height: 1.5;
    margin: 0;
    /* position: relative; */
    /* overflow: -moz-hidden-unscrollable; */
  }

  .container{
    max-width:1180px;
    margin:0 auto;
    @media (max-width:1280px) {
      max-width:1000px;
    }
    @media (max-width:1080px) {
      max-width:800px;
    }
    @media (max-width:880px) {
      max-width:600px;
      padding: 0 40px;
    }
    @media (max-width:600px) {
      max-width:600px;
      padding: 0 20px;
    }
    &--small{
      max-width: 930px;
      margin:0 auto;
    }
  }

  ol,
  ul {
    list-style: none;
    padding-left: 0;
  }

  h1 {
    margin: 0;
  }

  h2 {
    margin-top: 0;
    margin-bottom: 18px;
  }

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  /* Additional resets */
  a {
    text-decoration: none;
    color: inherit;
  }

  /* Fix antialiasing */
  *,
  *:before,
  *:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    outline: none;
  }
  .rotator__wrapper{
    overflow:hidden;
  }

    header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 20px 40px;
    z-index: 99999;
    background: #fff;
    box-shadow: 0 3px rgba(0,0,0,0.15);
    @media (max-width: 1280px) {
      padding: 32px 40px;
    }
    .container {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
    .logo {
      position: absolute;
      left: 40px;
      top: 50%;
      transform: translateY(-50%);
      @media (max-width: 600px) {
        left: 20px;
      }
    }
  }
  .header {
    &__lang {
      margin: -3px 34px 0 30px;
      @media (max-width: 1280px) {
        margin: 0;
        color: #fff;
        nav {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        a {
          margin: 0 10px;
        }
      }
      span {
        font-size: 1.2em;
      }
    }
    &__right {
      display: flex;
      align-items: center;
      @media (max-width: 1280px) {
        display: none !important;
      }
    }
    &__flag {
      display: flex;
      @media (max-width: 1280px) {
        background: #fff;
        justify-content: center;
        padding: 20px 0;
        position: absolute;
        bottom: 60px;
        left: 0;
        right: 0;
      }
    }
  }
  .hamburger {
    @media (min-width: 1281px) {
        display: none;
      }
    height: 20px;
    width: 30px;
    position: absolute;
    right: 40px;
    top: 20px;
    @media (max-width: 600px) {
      right: 20px;
    }
    span {
      height: 2px;
      width: 30px;
      background: #000000;
      position: absolute;
      transition: 0.3s all linear;
      border-radius: 2px;
    }
    span:nth-of-type(1) {
      top: 0;
      right: 0;
    }
    span:nth-of-type(2) {
      width: 25px;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }
    span:nth-of-type(3) {
      top: 100%;
      right: 0;
      transform: translateY(-100%);
    }
    &--active {
      span:nth-of-type(1) {
        top: 50%;
        transform: translateY(-50%) rotate(-45deg);
        color: #fff;
      }
      span:nth-of-type(2) {
        opacity: 0;
        transform: translate(-100%, -50%);
        color: #fff;
      }
      span:nth-of-type(3) {
        top: 50%;
        transform: translateY(-50%) rotate(45deg);
        color: #fff;
      }
    }
  }
  .mobileNav {
    position: absolute;
    /* top: 0; */
    top: 100%;
    height: 100vh;
    z-index: 200;
    width: 100%;
    left: 0;
    background: #000;
    transition: 0.3s all;
    transform: translateX(-100vw);
    @media (min-width: 1281px) {
     display: none;
    }
    &--active {
      transform: translateX(0);
    }
    li{
      width: 200px;
      text-align: center;
      &.has__child span:after{
        right: 0;
        top: 10px;
      }
      span,
      a{
        width: 100%;
        display: block;
       padding: 0;
      }
    }
  }
  main{
    padding-top:80px;
      @media (max-width: 1280px) {
        padding-top:60px;
      }
  }
  .footer{
    position: relative;
    z-index: 20;
    &:before{
      content:'';
      display: block;
      height:1px;
      width: 50%;
      background: #707070;
      transform: translateY(-1px);
      position: relative;
    }
    .container{
      padding-top: 70px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      @media (max-width: 880px) {
        padding-top: 50px;
        flex-direction: column;
        justify-content: center;
    }
    }

    &__info{
      max-width: 220px;
      width:100%;
      @media (max-width: 880px) {
        max-width: 100%;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
      }
      div{
        margin-top: 36px;
        margin-bottom: -10px;
      @media (max-width: 880px) {
        margin-top: 0;
        margin-bottom:0;
      }
        /* font-weight: 500; */
      }
      a{
        transition: 0.3s all;
        &:hover{
          opacity: 0.5;
        }
      }
      p{
        &:last-of-type{
          margin-top: 35px;
          margin-bottom: 0;
        }
      }
    }
    &__img{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      @media (max-width: 880px) {
        align-items: center;
       }
    }
    &__social{
      display: flex;
      align-items: center;
      margin-top: 5px;
      margin-bottom: 25px;
       @media (max-width: 880px) {
        justify-content: center;
       }
      a{
        display: block;
        margin: 0 30px 0 0 ;
        transition: 0.3s all;
        &:hover{
          opacity: 0.5;
        }
      }
    }
    &__socialMenu{
      max-width: calc(100% - 300px);
      width:100%;
      display: flex;
      border-bottom: 1px solid #707070;
      @media (max-width: 1280px) {
        max-width: calc(100% - 260px);
      }
      @media (max-width: 880px) {
        max-width: 100%;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        padding-top: 25px;
        border-bottom: none;
        /* position: relative; */
        
      }
      .footer__col{
        width: 200px;
        padding-bottom: 40px;
      }
    }
    &__copy{
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin: 80px 0 85px;
      color: #848484;
      @media (max-width: 880px) {
        margin: 35px 0 50px;
        text-align: center;
        &:after{
          content:'';
          position: absolute;
          display: block;
          /* bottom:0; */
          right: 0;
          height: 1px;
          left: 20vw;
          background: #707070;
        }
       }
    }
    &__nav{
      margin-left: 70px;
      width: calc( 100% - 270px);
      @media (max-width: 1280px) {
        margin-left: 30px;
        width: calc( 100% - 220px);
      }
      @media (max-width: 880px) {
        margin-left: 0;
        width: 100%;
        text-align: center;
        justify-content: center;
        align-items: center;
        display: flex;
        padding-bottom: 20px;
       }
      ul{
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        max-height: 260px;
        width: 100%;
        margin: 7px 0 0;
      }
      li{
        font-size: 14px;
        max-width: 150px;
        height: 52px;
        
        @media (max-width: 1080px) {
          display: none;
          &:nth-last-child(1),
          &:nth-last-child(2),
          &:nth-last-child(3),
          &:nth-last-child(4){
            display: block;
          }
        }
      }
      a{
        transition: 0.3s all;
        &:hover{
          opacity: 0.5;
        }
      }
    }
  }
  .rotator{
    padding: 40px 0 ;
    &__wrapper{
      .slick-track{
        display: flex;
        align-items: center
      }
      img{
        margin:0 auto;
      }
    }
    &__item{
      display: flex !important;
      align-items: center;
      justify-content: center;
    }
  }
  .half{
    display: flex;
    width:100%;
    padding-bottom:70px;
    @media (max-width: 880px) {
      flex-wrap: wrap;
      padding-bottom: 50px;
    }
    @media (max-width: 500px) {
      padding-bottom: 0px;
    }
    &:last-of-type {
      padding-bottom: 0;
    }
  }
  /* .container--small{
    max-width:650px;
    margin:0 auto;
  } */
  .descirption--small{
      font-size: 18px;
      font-weight: 300;
  }
  .page_career{
    .half{
      &:nth-of-type(2n){
        /* display: none; */
        @media (max-width:880px) {
          flex-direction: column-reverse;
        }
      }
    }
    .half__wrapper--image{
      @media (max-width:880px) {
      height: 400px;
      }
    }
    .half__wrapper{
      border: none !important;
    }
    .half:nth-of-type(1){
      h2{
        &:before{
          content: '';
          position: absolute;
          display: block;
          width: 70%;
          height: 1px;
          background: #333333;
          top: 27px;
          -webkit-transform: translate(50%,-50%);
          -ms-transform: translate(50%,-50%);
          transform: translate(0%,-50%);
          right: -70%;
          @media (max-width: 1080px) {
            content: unset
          }
        }
      }
    }
    .half:nth-of-type(3){
      h2{
        &:before{
          content: '';
          position: absolute;
          display: block;
          width: 40%;
          height: 1px;
          background: #333333;
          top: 27px;
          transform: translate(0%,-50%);
          right: -30%;
           @media (max-width: 1080px) {
            content: unset
          }
        }
      }
    }
    
    .half:nth-of-type(4){
      h2{
        &:before{
          content: '';
          position: absolute;
          display: block;
          width: 53%;
          height: 1px;
          background: #333333;
          top: 27px;
          transform: translate(0%,-50%);
          left: -59%;
           @media (max-width: 1080px) {
            content: unset
          }
        }
      }
      .half__wrapper--image {
          background-size: 86%;
        @media (max-width: 1080px) {
          background-size: cover;
        }
      }
    }
  }
  .casestudiesPage{
    .casestudies__desription{
      max-width: 633px;
      margin: -50px auto 40px;
    }
    .container__category{
      max-width:760px;
    }
    .blog__feed--3 .feed__box__title{
      font-size: 30px;
      line-height: 1.2;
      min-height: 120px;
    }
  }
  .blog__feed,
  .blogPage{
      .feed__box__title{
        font-size: 20px;
        line-height: 26px;
        font-weight: 500;
        @media (max-width: 1280px){
          font-size: 18px;
          line-height: 24px;
        }
      }
    }
  main.servicesPage .feed__box__title{
    font-size: 30px;
        line-height: 1.2;
  }
`

export default GlobalStyle
