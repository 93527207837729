import React from "react"
import Link from "gatsby-link"
import { useStaticQuery, graphql } from "gatsby"
import myInitObject from "../ultis/variable"
import uuid from "react-uuid"
import iconFacebook from "../../static/facebook.svg"
import iconLinkin from "../../static/linkedin.svg"

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      swapi {
        footerSingleton {
          footer_address
          footer_copy
          footer_facebookLink
          footer_linkedinLink
          footer_logo {
            path
          }
          link_footer_logo1
          footer_logo1 {
            path
          }
          footer_logo2 {
            path
          }
          link_footer_logo3
          fotoer_logo3 {
            path
          }
          footer_menu {
            value
          }
        }
      }
    }
  `)
  const { footerSingleton } = data.swapi
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__col footer__info">
          <Link to="/">
            <img
              src={myInitObject.cockpitUrl + footerSingleton.footer_logo.path}
              alt=""
            />
          </Link>
          <div
            dangerouslySetInnerHTML={{ __html: footerSingleton.footer_address }}
          ></div>
        </div>
        <div className="footer__col footer__socialMenu">
          <div className="footer__col">
            <div className="footer__social">
              <div className="footer__social__facebook">
                <a href={footerSingleton.footer_facebookLink} target="_blank">
                  <img src={iconFacebook} alt="" />
                </a>
              </div>
              <div className="footer__social__linkin">
                <a href={footerSingleton.footer_linkedinLink} target="_blank">
                  <img src={iconLinkin} alt="" />
                </a>
              </div>
            </div>
            <div className="footer__img">
              <div>
                <a href={footerSingleton.link_footer_logo1}>
                  <img
                    src={
                      myInitObject.cockpitUrl +
                      footerSingleton.footer_logo1.path
                    }
                    alt=""
                  />
                </a>
                <a href={footerSingleton.link_footer_logo3}>
                  <img
                    src={
                      myInitObject.cockpitUrl +
                      footerSingleton.fotoer_logo3.path
                    }
                    alt=""
                  />
                </a>
              </div>
              <img
                src={
                  myInitObject.cockpitUrl + footerSingleton.footer_logo2.path
                }
                alt=""
              />
            </div>
          </div>
          <div className="footer__nav">
            <nav>
              <ul>
                {footerSingleton.footer_menu.map(item => {
                  if (item.value.link.includes("javascript")) {
                    return (
                      <li key={uuid()}>
                        <a href={item.value.link}>{item.value.text}</a>
                      </li>
                    )
                  } else {
                    return (
                      <li key={uuid()}>
                        <Link to={item.value.link}>{item.value.text}</Link>
                      </li>
                    )
                  }
                })}
              </ul>
            </nav>
          </div>
        </div>
        <div className="footer__copy">
          <p>{footerSingleton.footer_copy}</p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
